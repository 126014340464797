<template>
  <div class="import-amazon-logistic">
    <v-text-field
      :disabled="pLoading"
      v-model="station"
      :label="
        $localizationService.localize(
          'drp_import.stepper.step.import_amazon.station'
        )
      "
      @input="emitLogisticsData()"
    ></v-text-field>

    <v-textarea
      v-if="!errorInData.text"
      :disabled="pLoading"
      v-model="logisticsData"
      :label="
        $localizationService.localize(
          'drp_import.stepper.step.import_amazon.amazon_logistics'
        )
      "
      @input="emitLogisticsData()"
    ></v-textarea>

    <div v-show="errorInData.text" class="error-container">
      <div class="backdrop" ref="backdrop">
        <div class="highlights" ref="highlights"></div>
      </div>
      <v-textarea
        ref="errorTextarea"
        :disabled="pLoading"
        v-model="logisticsData"
        :label="
          $localizationService.localize(
            'drp_import.stepper.step.import_amazon.amazon_logistics'
          )
        "
        no-resize
        @input="emitLogisticsData()"
        @scroll="recalcScroll()"
      ></v-textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportAmazonLogistics",

  props: {
    pLoading: Boolean,
    pTargetDate: String,
  },

  data: () => ({
    station: "",
    logisticsData: "",
    errorInData: {
      index: "",
      text: "",
    },
  }),

  methods: {
    clearData() {
      this.station = "";
      this.logisticsData = "";
      this.errorInData = {
        index: "",
        text: "",
      };
    },

    emitLogisticsData() {
      if (this.errorInData.text) {
        this.highlightBackground();
      }

      this.$emit("onInput", this.station && this.logisticsData);
    },

    async sendData() {
      await this.$store.dispatch("importAmazonDataModule/sendData", {
        importData: {
          station: this.station,
          logisticsData: this.logisticsData,
        },
        targetDate: this.pTargetDate,
      });
    },

    findErrorLine(errorIndex) {
      this.errorInData = {
        index: errorIndex,
        text: this.logisticsData.split(/\r?\n/)[errorIndex],
      };

      this.emitLogisticsData();

      setTimeout(() => {
        // TODO: refactor
        //* default vuetify textarea line-height: 1.75rem (28px)
        this.$refs.backdrop.scrollTop = 28 * errorIndex;
        this.$refs.errorTextarea.$refs.input.scrollTop = 28 * errorIndex;
      });
    },

    highlightBackground() {
      const errorRowInField = this.logisticsData.split(/\r?\n/)[this.errorInData.index];

      const highlightText = this.logisticsData.split(/\r?\n/);

      highlightText[this.errorInData.index] = `<mark>${
        highlightText[this.errorInData.index]
      }</mark>`;

      this.$refs.highlights.innerHTML = errorRowInField == this.errorInData.text
        ? highlightText.join("\r\n")
        : "";

      //* If the content in .highlights el was rewrote, we need to recalc .backdrop el scroll
      this.recalcScroll();
    },

    recalcScroll() {
      this.$refs.backdrop.scrollTop = this.$refs.errorTextarea.$refs.input.scrollTop;
    },
  },
};
</script>

<style lang="scss">
.import-amazon-logistic {
  .error-container {
    height: 170px !important;
    transform: translateZ(0);
    -webkit-text-size-adjust: none;

    .backdrop {
      position: absolute;
      z-index: 1;
      margin-top: 13px;
      overflow-y: scroll;
      height: 140px;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .highlights {
      white-space: pre-wrap;
      line-height: 1.75rem;
      word-wrap: break-word;
      color: transparent;
    }
    .v-textarea {
      width: -webkit-fill-available !important;
      position: absolute;
      z-index: 2 !important;
      margin: 0;
      .v-input__slot {
        height: 140px !important;
      }
    }
    mark {
      border-radius: 3px;
      color: transparent;
      background-color: rgba($color: #ff0000, $alpha: 0.5);
    }
  }
  .import-progress-circle {
    display: block;
    position: absolute;
    top: 5% !important;
    left: 95% !important;
  }
}
</style>
